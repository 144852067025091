import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["className", "displayImage", "displayName", "handleClick"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { DEFAULT_SPECIALS_CARD_IMG } from 'shared/constants';
import { replaceAWSSourceWithImgix } from 'shared/helpers/imgix';

function BogoMenuCard(_ref) {
  var _ref$className = _ref.className,
      className = _ref$className === void 0 ? 'BogoCard' : _ref$className,
      displayImage = _ref.displayImage,
      displayName = _ref.displayName,
      handleClick = _ref.handleClick,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx(BogoCardContainer, _extends({
    className: "bogo-card-container ".concat(className),
    imageUrl: replaceAWSSourceWithImgix(displayImage) || DEFAULT_SPECIALS_CARD_IMG,
    onClick: handleClick
  }, props), __jsx(TextContainer, null, __jsx(Title, null, displayName)), __jsx(ActionsContainer, null, __jsx(ShopButton, {
    onClick: handleClick
  }, "SHOP")));
}

var ShopButton = styled.button.withConfig({
  displayName: "bogo-menu-card__ShopButton",
  componentId: "sc-1grazy4-0"
})(["width:65px;height:32px;background:transparent;font-weight:bold;font-size:12px;line-height:13px;text-transform:uppercase;color:#ffffff;border:2px solid #ffffff;border-radius:", ";&:hover{cursor:pointer;}"], function (_ref2) {
  var _theme$customized$rad, _theme$customized, _theme$customized$rad2;

  var theme = _ref2.theme;
  return (_theme$customized$rad = theme === null || theme === void 0 ? void 0 : (_theme$customized = theme.customized) === null || _theme$customized === void 0 ? void 0 : (_theme$customized$rad2 = _theme$customized.radius) === null || _theme$customized$rad2 === void 0 ? void 0 : _theme$customized$rad2.buttons) !== null && _theme$customized$rad !== void 0 ? _theme$customized$rad : '20px';
});
var ActionsContainer = styled.div.withConfig({
  displayName: "bogo-menu-card__ActionsContainer",
  componentId: "sc-1grazy4-1"
})([""]);
var Title = styled.p.withConfig({
  displayName: "bogo-menu-card__Title",
  componentId: "sc-1grazy4-2"
})(["font-weight:bold;font-size:16px;line-height:120%;color:#ffffff;text-shadow:0px 2px 4px rgba(152,163,173,0.36);width:100%;word-break:break-word;"]);
var TextContainer = styled.div.withConfig({
  displayName: "bogo-menu-card__TextContainer",
  componentId: "sc-1grazy4-3"
})(["display:flex;align-items:center;justify-content:center;padding-bottom:24px;max-width:232px;"]);
var BogoCardContainer = styled.div.withConfig({
  displayName: "bogo-menu-card__BogoCardContainer",
  componentId: "sc-1grazy4-4"
})(["padding:29px;background:", ";background-size:cover;width:100%;max-width:383px;min-height:165px;border-radius:20px;display:flex;flex-direction:column;justify-content:flex-end;margin:0 19px 19px 0;box-shadow:0px 4px 14px rgba(0,0,0,0.25);flex:1 0 auto;background-position:center left;&:hover{cursor:pointer;button{border:2px solid #fff;background-color:#fff;color:#242526;}}@media (min-width:1280px){margin-right:23px;width:325px;box-shadow:none;}"], function (_ref3) {
  var imageUrl = _ref3.imageUrl;
  return "linear-gradient(180deg, rgba(11, 31, 50, 0.432) 33.75%, rgba(11, 31, 50, 0.9) 100%), url(".concat(imageUrl, ")");
});
export default BogoMenuCard;