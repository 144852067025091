import SpecialsPage from 'src/dispensary/specials';
import { MainDispensaryLayout } from 'components/layouts/main-dispensary-layout';
import { getStaticPaths } from 'utils/ssr';
import { generateConsumerDispensaryStaticProps } from 'src/dispensary/utils/ssr/generate-consumer-dispensary-static-props';

export { getStaticPaths };

export const getStaticProps = async (context) => generateConsumerDispensaryStaticProps(context);

SpecialsPage.layout = MainDispensaryLayout;
export default SpecialsPage;
