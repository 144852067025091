import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useRouter } from 'next/router';

import useTranslation from 'hooks/use-translation';

import BogoMenuCard from 'shared/components/specials/bogo-menu-card';
import { DesktopOnly, MobileOnly } from 'shared/components';
import { sortSpecialCards } from 'shared/helpers/specials';
import { Typography } from 'src/typography';
import { BreadCrumbs } from 'src/components/bread-crumbs';
import { useIsMobile } from 'src/hooks/use-is-mobile';

function OfferMenuSection({ UI, dispensary, specials }) {
  const { t } = useTranslation();
  const router = useRouter();
  const isMobile = useIsMobile();
  const { isDutchieMain } = UI;

  const specialsToRender = specials;
  const pageTitle = t(`common.todaysSpecials`, `Today's Specials`);
  const pageDescription = t(`specials.clickASpecial`, `Shop today's sales and special offers!`);

  const sortedByExpiration = sortSpecialCards(specialsToRender);
  const numPlaceholdersToRender = !(sortedByExpiration.length % 3) ? 0 : 3 - (sortedByExpiration.length % 3);

  async function handleClick({ _id, specialType }) {
    const specialTypeSubRoute = specialType === `sale` ? specialType : `offer`;
    await router.push(`/${UI.dispensaryRootUrl}/${dispensary.cName}/specials/${specialTypeSubRoute}/${_id}`);
  }

  function renderGridPlaceholders() {
    return _.map(Array(numPlaceholdersToRender), (__, index) => (
      <NonVisiblePlaceholder key={`offers_grid_placeholder_${index}`} aria-hidden className='bogo-card-container' />
    ));
  }

  return (
    specialsToRender.length > 0 && (
      <Section>
        {isDutchieMain && (
          <BreadCrumbsContainer isMobile={isMobile}>
            <BreadCrumbs />
          </BreadCrumbsContainer>
        )}
        <Typography.Heading size='medium' tag='h1'>
          {pageTitle}
        </Typography.Heading>
        <DesktopOnly display='block'>
          <Label>{pageDescription}</Label>
        </DesktopOnly>
        <MobileOnly display='block'>
          <Label>{pageDescription}</Label>
        </MobileOnly>
        <OuterContainer>
          {_.map(sortedByExpiration, (special, i) => (
            <BogoMenuCard
              key={special._id}
              displayName={special.menuDisplayName || special.name}
              displayImage={special.menuDisplayImage}
              handleClick={() => handleClick(special)}
              className={`BogoCard_${i}`}
            />
          ))}
          {/* the last row can calculate the widths of the cards incorrectly
              so this forces some invisible elements in to keep widths consistent */}
          {renderGridPlaceholders()}
        </OuterContainer>
      </Section>
    )
  );
}

const Label = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[35]};
  line-height: 30px;
  margin: 0 0 14px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 25px;
    margin: 0;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 15px 0 16px 0;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 20px 15px;
  display: inline-flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: -15px; // offset the bottom padding

  &&& .bogo-card-container {
    max-width: 100%;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 600px) {
    justify-content: space-between;

    &&& .bogo-card-container {
      margin-right: 0;
      margin-left: 0;
      max-width: calc(50% - 12px);
    }

    &:nth-child(odd) {
      margin-left: 0;
    }
  }

  @media (min-width: 960px) {
    padding-left: 0;
  }

  @media (min-width: 1280px) {
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;

    &&& .bogo-card-container {
      margin-right: 25px;

      :nth-child(3n + 3) {
        margin-right: -20px;
      }
    }
  }
`;

const NonVisiblePlaceholder = styled.div`
  visibility: hidden;
  width: 383px;
`;

const Section = styled.div`
  margin-top: 44px;
  @media (min-width: 960px) and (max-width: 1280px) {
    padding-left: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: unset;
  }
`;

const BreadCrumbsContainer = styled.div`
  margin: ${({ isMobile }) => (isMobile ? `25px 0 -25px` : `-18px 0 24px -25px`)};
`;

export default OfferMenuSection;
