import React, { useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { AmplitudeEvents, useAmplitude } from 'shared/hooks/use-amplitude';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import useDispensarySpecials from 'src/dispensary/hooks/use-dispensary-specials';
import useUI from 'hooks/use-ui';
import { useProductFiltersApplied } from 'hooks/use-product-filters-applied';

import { MobileOnly } from 'shared/components';
import Menu from 'components/menu';
import OfferMenuSection from 'components/offer-menu-section';
import SubcategoriesPills from 'components/subcategories-pills';
import ContentWrapper from 'components/core/content-wrapper';

export default function SpecialsPage() {
  const UI = useUI();
  const { dispensary } = useDispensary();
  const { products, totalPages, loading } = useDispensaryProducts({ filters: { isOnSpecial: true } });
  const { specials } = useDispensarySpecials({ filteredProducts: products });
  const amplitude = useAmplitude();

  useEffect(() => {
    amplitude.log(AmplitudeEvents.menu.viewSpecials, {
      dispensaryId: dispensary?.id,
      dispensaryName: dispensary?.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productFiltersApplied = useProductFiltersApplied();
  const displaySidebar = !(_.isEmpty(products) && !productFiltersApplied);

  const displayedSalesSpecials = _.filter(
    specials,
    (special) => _.isNil(special.specialType) || special.specialType === `sale`
  );
  const hasActiveSpecials = !_.isEmpty(specials);
  const onlyBogoSpecialsActive = _.every(specials, { specialType: `bogo` });
  const bogoAndSalesSpecialsActive = _.some(specials, { specialType: `bogo` }) && displayedSalesSpecials.length > 0;
  /*
    product wants to filter out all products if the only offer is
    minimum spend and there are no other conditions on the offer
    so we don't want to show the empty specials state component
    in this case only
   */
  const onlySolitaryMinSpendOfferActive = _.every(
    specials,
    ({ specialType, totalSpend, bogoConditions = [] }) =>
      // offers with only advanced conditions don't have a bogoConditions array
      specialType === `bogo` && (!bogoConditions || bogoConditions?.length === 0) && totalSpend?.enabled
  );

  // this check prevents an empty state from rendering below the bogo specials...
  // in the case where either there are bogo specials and no sales specials,
  // or there are both but the sales specials have no available products...
  // (e.g. all product(s) on sales specials are out of stock/inactive)
  const hasBothSpecialsActiveButSaleProductsAreEmpty =
    bogoAndSalesSpecialsActive && _.isEmpty(products) && !productFiltersApplied;

  const hideHeaderAndProductList =
    hasActiveSpecials &&
    (onlyBogoSpecialsActive || hasBothSpecialsActiveButSaleProductsAreEmpty) &&
    onlySolitaryMinSpendOfferActive;

  if (!dispensary) {
    return null;
  }

  return (
    <ContentWrapper>
      <OffersContainer>
        <OfferMenuSection UI={UI} dispensary={dispensary} specials={specials} />
      </OffersContainer>
      <MobileOnly>
        <PillContainer>
          <SubcategoriesPills isSpecials />
        </PillContainer>
      </MobileOnly>
      <Menu
        products={products}
        loading={loading}
        dispensary={dispensary}
        page='specials'
        totalPages={totalPages}
        displaySidebar={displaySidebar}
        hideHeaderAndProductList={hideHeaderAndProductList}
      />
    </ContentWrapper>
  );
}

const OffersContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    h1 {
      padding-top: 44px;
      padding-left: 25px;
    }
  }
`;

const PillContainer = styled.div`
  overflow: auto;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    .subcategories-pills {
      margin-top: 36px;
    }
  }
`;
